import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { courses } from '../constants/courses';

class WalletStoreModel {
	storageKey = 'wallet-chamber';
	scores = {};
	dates = {};
	exam = {};

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: this.storageKey,
			properties: ['scores', 'dates'],
			storage: localStorage,
		});
	}

	get currentCourse() {
		if (!Object.keys(this.scores)?.length) {
			return null;
		}

		return courses.find((key) => this.scores[key] !== 100) || null;
	}

	get latestCompleteCourse() {
		if (!Object.keys(this.scores)?.length) {
			return null;
		}

		return courses.findLast((key) => this.scores[key] === 100) || null;
	}

	get coursesInProgress() {
		return Object.keys(this.scores)?.length ? Object.values(this.scores).some((score) => score > 0) : false;
	}

	get coursesPassed() {
		return Object.keys(this.scores)?.length && Object.values(this.scores).every((score) => score === 100);
	}

	setScore = (course, value) => {
		this.scores = { ...this.scores, [course]: value };
	};

	getScore = (course) => {
		return this.scores[course];
	};

	setDate = (course, value) => {
		this.dates = { ...this.dates, [course]: value };
	};

	getDate = (course) => {
		return this.dates[course];
	};

	setExam = (value) => {
		this.exam = value;
	};
	reset = () => {
		this.scores = {};
		this.dates = {};
		this.exam = {};
	};
}

class PopupStoreModel {
	isOpen = false;
	constructor() {
		makeAutoObservable(this);
	}
	togglePopup = () => {
		this.isOpen = !this.isOpen;
	};
	closePopup = () => {
		this.isOpen = false;
	};
	openPopup = () => {
		this.isOpen = true;
	};
}

class UserStoreModel {
	user = null;
	userStatusCode = null;
	userJustBoarded = false;

	constructor() {
		makeAutoObservable(this);
	}
	setUser = (value) => {
		this.user = value;
	};
	getUser = () => {
		return this.user;
	};
	setUserStatusCode = (value) => {
		this.userStatusCode = value;
	};
	setUserJustBoarded = (value) => {
		this.userJustBoarded = value;
	};
	getUserStatusCode = () => {
		return this.userStatusCode;
	};
	reset = () => {
		this.user = null;
		this.userStatusCode = null;
	};
}

class AuthStoreModel {
	authProfile = null; // auth.userData.profile
	onSignIn = null; // auth.signIn()
	onSignOut = null; // auth.signOut()
	constructor() {
		makeAutoObservable(this);
	}
	setAuthProfile = (value) => {
		this.authProfile = value;
	};
	setSignIn = (value) => {
		this.onSignIn = value;
	};
	setSignOut = (value) => {
		this.onSignOut = value;
	};
	reset = () => {
		this.authProfile = null;
		this.onSignIn = null;
		this.onSignOut = null;
	};
}

class QrStoreModel {
	qrIsOpen = false;
	qrUrl = null;
	qrValidity = null;
	constructor() {
		makeAutoObservable(this);
	}
	toggleQr = () => {
		this.qrIsOpen = !this.qrIsOpen;
	};
	closeQr = () => {
		this.qrIsOpen = false;
	};
	openQr = () => {
		this.qrIsOpen = true;
	};
	setQrUrl = (value) => {
		this.qrUrl = value;
	};
	setQrValidity = (value) => {
		this.qrValidity = value;
	};
	reset = () => {
		this.qrIsOpen = false;
		this.qrUrl = null;
		this.qrValidity = null;
	};
}

class GuidesStoreModel {
	allRightMessageType = ''; // 'onboarded' | 'get-progress'
	isGuideStepUpdate = false;
	constructor() {
		makeAutoObservable(this);
	}
	setAllRightMessageType = (value) => {
		this.allRightMessageType = value;
	};
	guideStepUpdate = () => {
		this.isGuideStepUpdate = !this.isGuideStepUpdate;
	};
	reset = () => {
		this.allRightMessageType = '';
		this.isGuideStepUpdate = false;
	};
}

export const STORES = {
	PopupStore: new PopupStoreModel(),
	WalletStore: new WalletStoreModel(),
	UserStore: new UserStoreModel(),
	AuthStore: new AuthStoreModel(),
	QrStore: new QrStoreModel(),
	GuidesStore: new GuidesStoreModel(),
};
