import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { GET_SCORE, SEND_PDF, UPDATE_SCORE } from '../../services/api-calls';
import { IMAGES } from '../../constants/images';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { courses } from '../../constants/courses.js';
import { useBreadcrumbs } from '../components/breadcrumbs';
import { PATHS } from '../../constants/paths';

export const ProgressPage = observer(() => {
	const { WalletStore, AuthStore } = useStores();
	const { course } = useParams();
	const { pathname } = useLocation();
	const { setBreadcrumbs } = useBreadcrumbs();
	const { getScore, scores } = WalletStore;
	const { authProfile } = AuthStore;
	const score = getScore(course);

	const [localLoader, setLocalLoader] = useState(false);
	const { t } = useTranslation();

	const fetchScore = useCallback(() => {
		setLocalLoader(true);

		GET_SCORE(course).finally(async () => {
			setLocalLoader(false);
		});
	}, [course]);

	const updateScore = useCallback(() => {
		setLocalLoader(true);

		UPDATE_SCORE(course).finally(() => {
			setLocalLoader(false);
		});
	}, [course]);

	const sendPdf = useCallback(() => {
		setLocalLoader(true);

		SEND_PDF(course).finally(() => {
			setLocalLoader(false);
		});
	}, [course]);

	useEffect(() => {
		setBreadcrumbs([{ label: 'crafts' }, { label: 'user' }, { label: course, path: pathname }]);
	}, [course, pathname, setBreadcrumbs]);

	useEffect(() => {
		// If page is reloaded it will pull scores. If not (e.g. we're navigated here from /crafts) we don't need to pull score
		// This prevents a bug where we register for the course first and immediately pull scores. Scores and dates don't have a chance to be updated on backend, so we pulling null scores and dates
		if (authProfile && sessionStorage.getItem('access_token-chamber') && Object.keys(scores).length === 0) {
			fetchScore();
		}
	}, [authProfile, scores, fetchScore]);

	if (!authProfile || !sessionStorage.getItem('access_token-chamber')) {
		return (
			<main className="progress-main">
				<Typography variant="h2" className="mb-16">
					{t('progress.unauthorized.message')}
				</Typography>
				<Link to={PATHS.user}>{t('progress.unauthorized.back')}</Link>
			</main>
		);
	}

	return (
		<main className="progress-main">
			{score == null ||
				(score < 100 && (
					<div className="score-block">
						<Typography variant="h1">{t('progress.score-progress.title')}</Typography>
						<Typography className="mt-16 mb-16">
							<Trans i18nKey="progress.score-progress.text" values={{ userName: authProfile?.preferred_username }} />
						</Typography>
						<Button id="update-score-button" disabled={localLoader} variant="contained" size="large" onClick={updateScore}>
							{t('progress.score-progress.next-button')}
						</Button>
					</div>
				))}
			{score === 100 && (
				<div className="score-block">
					<Typography variant="h1">{t('progress.score-success.title')}</Typography>
					<Typography className="mt-16 mb-16">
						<Trans
							i18nKey="progress.score-success.text"
							values={{
								userName: authProfile?.preferred_username,
								course: t(`courses.${course}.name`),
							}}
						/>
					</Typography>
					<Button id="send-pdf-button" disabled={localLoader} variant="contained" size="large" onClick={sendPdf}>
						{t('progress.score-success.next-button')}
					</Button>
					{course === courses[0] ? (
						<>
							<Typography className="mt-16 mb-16">
								<Trans i18nKey="progress.score-success.back-text" values={{ course: t(`courses.${courses[1]}.name`) }} />
							</Typography>
							<Link to={PATHS.crafts}>
								<Button id="back-button" variant="contained" size="large">
									{t('progress.score-success.back-button')}
								</Button>
							</Link>
						</>
					) : (
						<>
							<Typography className="mt-16 mb-16">{t('progress.score-success.back-text-courses-complete')}</Typography>
							<Link to={PATHS.crafts}>
								<Button id="back-button" variant="contained" size="large">
									{t('progress.score-success.back-button-courses-complete')}
								</Button>
							</Link>
						</>
					)}
					<img src={IMAGES.congrat} alt="img" />
				</div>
			)}
		</main>
	);
});
