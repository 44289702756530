export const coursesData = {
	'craftsmen-master-level-one': {
		dates: ['01.02. - 31.10.', '01.04. - 31.12.'],
	},
	'craftsmen-master-level-two': {
		dates: ['01.11. - 14.02.', '01.01. - 14.03.'],
	},
};

export const courses = Object.keys(coursesData);
