import React from 'react';
import { Typography } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RestartScenarioButton } from './restart-scenario-button';
import { versionsFormater } from 'common-frontend/utils/formatters';
import { ResetScoreButton } from './reset-score-button';
import { SHOW_RESET_LEARNING_PROGRESS } from 'common-frontend/constants/env';

export const FooterUser = observer(() => {
	const { pathname } = useLocation();
	const { course } = useParams();
	const { WalletStore, UserStore, AuthStore } = useStores();
	const { getScore } = WalletStore;
	const { user, userStatusCode } = UserStore;
	const { authProfile } = AuthStore;

	const { t } = useTranslation();

	return (
		<footer className="simple">
			<Typography>{t('footer-user.title')}</Typography>
			{(user || (authProfile && userStatusCode === '403')) && !(pathname.includes('progress') && getScore(course) === 100) && (
				<RestartScenarioButton />
			)}
			{SHOW_RESET_LEARNING_PROGRESS && <ResetScoreButton />}
			<div className="versions-container">
				<VersionsMemo />
			</div>
		</footer>
	);
});

const VersionsMemo = React.memo(function VersionsMemo() {
	return <Typography variant="caption">{versionsFormater()}</Typography>;
});
