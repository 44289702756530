import React, { createContext, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import { PATHS } from '../../constants/paths';
import { useTranslation } from 'react-i18next';

const BreadcrumbsContext = createContext(undefined);

export const BreadcrumbsProvider = ({ children }) => {
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const contextProps = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);

	return (
		<BreadcrumbsContext.Provider value={contextProps}>
			<BreadcrumbsComponent />
			{children}
		</BreadcrumbsContext.Provider>
	);
};

const BreadcrumbsComponent = () => {
	const { t } = useTranslation();
	const { breadcrumbs } = useBreadcrumbs();

	return !breadcrumbs?.length ? null : (
		<Breadcrumbs aria-label="breadcrumbs" className="breadcrumbs mb-24">
			{breadcrumbs.map((crumb) => (
				<Link id={`crumb-${crumb.label}`} to={crumb.path || PATHS[crumb.label]} key={crumb.label}>
					{t(`nav.${crumb.label}`)}
				</Link>
			))}
		</Breadcrumbs>
	);
};

export const useBreadcrumbs = () => {
	const context = useContext(BreadcrumbsContext);
	if (context === undefined) {
		throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider');
	}
	return context;
};
