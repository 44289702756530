import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export const HomePage = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="header-banner"></div>
			<main className="main">
				<Grid container spacing={4}>
					<Grid item lg={12} md={12}>
						<Typography variant="h1" className="mt-48 mb-16">
							{t('home.main.title')}
						</Typography>
						<Trans i18nKey="home.main.description">
							<></>
							<ul>
								<li></li>
							</ul>
						</Trans>
					</Grid>
				</Grid>
			</main>
		</>
	);
};
