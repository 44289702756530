import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths';
import { signIn } from 'common-frontend/utils/auth-handlers';
import { AllRightMessagePopup, onCleanNodes, onPulse, onPulseClass, onTooltip } from 'common-frontend/components/guides';

export const Guides = observer(() => {
	const { pathname } = useLocation();
	const { t, i18n } = useTranslation();
	const { AuthStore, QrStore, UserStore, GuidesStore, WalletStore } = useStores();
	const { authProfile } = AuthStore;
	const { qrIsOpen } = QrStore;
	const { user, userStatusCode } = UserStore;
	const { allRightMessageType, setAllRightMessageType, isGuideStepUpdate } = GuidesStore;
	const { getScore, exam } = WalletStore;
	const course = pathname.split('/')[2];
	const score = course ? getScore(course) : null;
	const coursesPassed = WalletStore.coursesPassed;

	const debounceRef = useRef(0);
	const currStepRef = useRef('');

	const [allRightPopupIsOpen, setAllRightPopupIsOpen] = useState(false);

	const stepRunners = useMemo(
		() => ({
			homePageUnauthorized() {
				onCleanNodes(['auth-link']);
				onPulse('crafts-link');
			},
			craftsPageUnauthorized() {
				onPulse('auth-link');
			},
			userPageUnauthorized() {
				onCleanNodes(['crafts-link']);
				onPulse('auth-link');
			},
			userAuthorizedNotEnrolled() {
				onCleanNodes(['auth-link', 'crumb-crafts']);
				onPulse('qr-button');
				onTooltip('qr-button', t('tooltips.click-for-qr'));
			},
			qrOpened() {
				onCleanNodes(['qr-button']);
				onTooltip('qr-tooltip-anchor', t('tooltips.scan-qr'));
				setAllRightMessageType('onboarded');
			},
			userNotBoarded() {
				onCleanNodes(['auth-link', 'qr-button']);
				setAllRightMessageType('onboarded');
			},
			userBoarded() {
				onCleanNodes(['auth-link']);
				setAllRightPopupIsOpen(true);
			},
			boardedMessageClosed() {
				onCleanNodes(['auth-link']);
				onPulseClass('go-to-course-container');
			},
			userOpenedCourse() {
				onCleanNodes(['progress-link']);
				onPulse('update-score-button');
			},
			// userGotScore() {
			// 	onCleanNodes([ 'get-score-button' ]);
			// 	setAllRightPopupIsOpen(true);
			// },
			scoreMessageClosed() {
				onPulse('update-score-button');
			},
			userFinishedCourse() {
				onCleanNodes(['update-score-button']);
				onPulse('send-pdf-button');
			},
			userGoesToCraftsWithCoursesInProgress() {
				onPulse(`register-to-${WalletStore.currentCourse}`);
			},
			userGoesToCraftsWithCoursesInProgressCourseRegisterClicked() {
				onCleanNodes([`register-to-${WalletStore.currentCourse}`]);
			},
			userGoesToCraftsWithPassedCourses() {
				onCleanNodes(['send-pdf-button']);
				onPulse('exam-registration-button');
			},
			userGoesToCraftsWithPassedCoursesRegisterButtonPressed() {
				onCleanNodes(['exam-registration-button']);
			},
			// waitingForSendingCertificate() {
			// 	onPulse('send-j-cert-button');
			// },
			// waitingForSendingCertificateButtonPressed() {
			// 	onCleanNodes([ 'send-j-cert-button' ]);
			// 	setAllRightMessageType('journeyman-certificate');
			// 	setAllRightPopupIsOpen(true);
			// }
		}),
		[setAllRightMessageType, t, WalletStore.currentCourse],
	);

	const updateStep = useCallback(
		(forceUpdate = false) => {
			const validationRules = {
				homePageUnauthorized:
					pathname === PATHS.home && !authProfile && !sessionStorage.getItem('access_token-chamber') && !allRightMessageType,
				craftsPageUnauthorized:
					pathname === PATHS.crafts && !authProfile && !sessionStorage.getItem('access_token-chamber') && !allRightMessageType,
				userPageUnauthorized:
					pathname === PATHS.user && !sessionStorage.getItem('access_token-chamber') && !authProfile && !allRightMessageType,
				userAuthorizedNotEnrolled: authProfile && !qrIsOpen && !user && userStatusCode !== '403',
				qrOpened: authProfile && qrIsOpen && !user,
				userNotBoarded: authProfile && !qrIsOpen && !user && userStatusCode === '403',
				userBoarded: authProfile && user && allRightMessageType === 'onboarded',
				boardedMessageClosed: pathname === PATHS.user && authProfile && user && !allRightMessageType,
				userOpenedCourse: pathname.includes('progress') && authProfile && !allRightMessageType && !score,
				// userGotScore: pathname.includes('progress') && authProfile && allRightMessageType === 'get-progress',
				scoreMessageClosed: pathname.includes('progress') && authProfile && score === 0,
				userFinishedCourse: pathname.includes('progress') && authProfile && score === 100,
				userGoesToCraftsWithCoursesInProgress:
					pathname === PATHS.crafts &&
					WalletStore.currentCourse &&
					!sessionStorage.getItem(`${WalletStore.currentCourse}_register_button_clicked`),
				userGoesToCraftsWithCoursesInProgressCourseRegisterClicked:
					pathname === PATHS.crafts &&
					WalletStore.currentCourse &&
					sessionStorage.getItem(`${WalletStore.currentCourse}_register_button_clicked`),
				userGoesToCraftsWithPassedCourses:
					pathname === PATHS.crafts &&
					authProfile &&
					coursesPassed &&
					exam?.state === 'NOT_REGISTERED' &&
					!sessionStorage.getItem('exam_register_button_clicked'),
				userGoesToCraftsWithPassedCoursesRegisterButtonPressed:
					pathname === PATHS.crafts &&
					authProfile &&
					coursesPassed &&
					exam?.state === 'NOT_REGISTERED' &&
					sessionStorage.getItem('exam_register_button_clicked') === 'true',
				// waitingForSendingCertificate: pathname === PATHS.crafts && authProfile && coursesPassed && exam?.state === 'WAITING_FOR_FILE' && !sessionStorage.getItem('send_journeyman_certificate_button_pressed'),
				// waitingForSendingCertificateButtonPressed: pathname === PATHS.crafts && authProfile && coursesPassed && exam?.state === 'WAITING_FOR_FILE' && sessionStorage.getItem('send_journeyman_certificate_button_pressed') === 'true',
			};

			const stepValidator = () => {
				for (let key in validationRules) {
					if (validationRules[key]) {
						return key;
					}
				}
			};

			const setNewStep = () => {
				const newStep = stepValidator();

				if (newStep && (currStepRef.current !== newStep || forceUpdate)) {
					const allSteps = Object.keys(stepRunners);
					const newStepIndex = allSteps.indexOf(newStep);
					const maxDoneStepIndex = allSteps.indexOf(sessionStorage.getItem('max_done_guide_step'));

					if (maxDoneStepIndex < newStepIndex) {
						sessionStorage.setItem('max_done_guide_step', newStep.toString());
					}

					currStepRef.current = newStep;
					console.info('Run step', currStepRef.current);
					stepRunners[currStepRef.current]();
				}
			};

			return setNewStep();
		},
		[
			WalletStore.currentCourse,
			allRightMessageType,
			authProfile,
			coursesPassed,
			exam?.state,
			pathname,
			qrIsOpen,
			score,
			stepRunners,
			user,
			userStatusCode,
		],
	);

	useEffect(() => {
		const handleUpdate = () => {
			clearTimeout(debounceRef.current);
			debounceRef.current = setTimeout(updateStep, 300);
		};
		handleUpdate();
	}, [authProfile, qrIsOpen, user, allRightMessageType, pathname, score, exam, userStatusCode, updateStep]);

	useEffect(() => {
		clearTimeout(debounceRef.current);
		updateStep(true);
	}, [i18n.language, isGuideStepUpdate, updateStep]);

	const allRightHandler = useCallback(() => {
		switch (allRightMessageType) {
			case 'login':
				signIn();
				break;
			case 'get-progress':
				onPulse('update-score-button');
		}

		setAllRightMessageType('');
		setAllRightPopupIsOpen(false);
	}, [allRightMessageType, setAllRightMessageType]);

	return <AllRightMessagePopup messageType={allRightMessageType} isOpen={allRightPopupIsOpen} handler={allRightHandler} />;
});
