import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { MainLayout } from './main-layout';
import { HomePage } from '../pages/home';
import { ErrorPage } from 'common-frontend/components/error';
import { Commerce } from '../pages/commerce';
import { Crafts } from '../pages/crafts';
import { UserLayout } from './user-layout';
import { UserPage } from '../pages/user';
import { ProgressPage } from '../pages/progress';
import { PATHS } from '../../constants/paths';
import { useStores } from 'common-frontend/components/store-provider';

export const RoutesComponent = observer(() => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const {
		UserStore: { userJustBoarded },
	} = useStores();

	useEffect(() => {
		if (userJustBoarded && pathname !== PATHS.crafts) {
			navigate(PATHS.crafts);
		}

		// passing pathname as dependency there's gonna be a redirection to /crafts whenever user chooses other path
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userJustBoarded]);

	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route path={PATHS.home} element={<HomePage />} errorElement={<ErrorPage />} />
				<Route path={PATHS.commerce} element={<Commerce />} errorElement={<ErrorPage />} />
				<Route path={PATHS.crafts} element={<Crafts />} errorElement={<ErrorPage />} />
			</Route>
			<Route element={<UserLayout />}>
				<Route path={PATHS.user} element={<UserPage />} errorElement={<ErrorPage />} />
				<Route path={PATHS.course} element={<ProgressPage />} errorElement={<ErrorPage />} />
			</Route>
			<Route path="*" element={<ErrorPage message={'404 page not found'} />} />
		</Routes>
	);
});
